import * as React from "react";
import { Block, Container, Element } from "react-bulma-components";
import Obfuscate from "react-obfuscate";
import styled from "styled-components";

import { faFacebookSquare, faYoutubeSquare } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Layout, Subtitle, Title } from "../components/index";

const DivShaded = styled.div`
    margin: 0 auto;
    width: fit-content;
    text-align: center;
    background-color: ${props => props.theme.color.shadedBackground};
    border-radius: 100px;
`;

const ElementIconsWrapper = styled(Element)`
    --iconsOuterGap: 2rem;

    & > :nth-child(1) {
        margin-left: var(--iconsOuterGap);
    }

    & > :last-child {
        margin-right: var(--iconsOuterGap);
    }

    & > a {
        display: inline-block;
        margin: 0.5rem 0.5rem;
        color: white;
        text-decoration: none;
        font-size: 2.5rem;
    }
`;

const Contact = (props) => {
    return (
        <Layout {...props}>
            <Title>Contact</Title>

            <Container>
                <Block>
                    <Subtitle>Email</Subtitle>

                    <DivShaded>
                        <ElementIconsWrapper>
                            <Obfuscate email="info@tootalltyler.com" >
                                <FontAwesomeIcon icon={faEnvelope} />
                            </Obfuscate>
                        </ElementIconsWrapper>
                    </DivShaded>
                </Block>

                <Block>
                    <Subtitle>Follow</Subtitle>

                    <DivShaded>
                        <ElementIconsWrapper textSize={2}>
                            <a href="https://www.facebook.com/Too-Tall-Tyler-and-The-Tattletales-534355493670658/" rel="noreferrer" target="_blank">
                                <FontAwesomeIcon icon={faFacebookSquare} />
                            </a>

                            <a href="https://www.youtube.com/channel/UCnLg7gjkAKdfggw69DYkpmQ" rel="noreferrer" target="_blank">
                                <FontAwesomeIcon icon={faYoutubeSquare} />
                            </a>
                        </ElementIconsWrapper>
                    </DivShaded>
                </Block>
            </Container>
        </Layout>
    );
};

export default Contact;